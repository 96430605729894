<template>
  <b-card>
    <b-row class="d-flex justify-content-between mx-25 flex-wrap my-1">
      <div class="d-flex">
        <h4 class="mr-2">
          {{ $t("backoffice.settings.subtitle-locations") }}
        </h4>
        <b-form-checkbox
          v-if="parentHasRoomsAppInstalled && isSubcommunity"
          v-model="hasRoom"
          switch
          @change="changeRoomToLocation"
        >
          {{ $t("backoffice.settings.link-to-room") }}
        </b-form-checkbox>
      </div>
      <div class="d-flex ml-25">
        <b-button v-if="!hasRoom" variant="primary" @click="addLocationCouple()">
          <feather-icon icon="PlusCircleIcon" class="text-white" />
        </b-button>
        <b-button variant="primary" class="ml-1" @click="saveLocations()">
          {{ $t("form.actions.save") }}
        </b-button>
      </div>
    </b-row>
    <div v-if="parentHasRoomsAppInstalled && hasRoom">
      <b-form-group
        :label="$t('organizations.edit.rooms.label')"
        label-for="profile-room"
      >
        <v-select
          v-model="roomKey"
          :options="roomOptions"
          :reduce="(x) => x.key"
          :clearable="false"
          @input="changeMap()"
        />
      </b-form-group>
    </div>
    <div v-else>
      <b-row class="d-flex justify-content-between mb-1">
        <b-col>
          <div v-if="isFirstLocationSectionVisible" class="d-flex flex-wrap">
            <!-- Name -->
            <b-form-group class="flex-grow-1">
              <label for="link-name" class="font-weight-bold">
                {{ $t("backoffice.settings.name") }}</label>
              <b-form-input
                id="link-name"
                v-model="currentLocation.name"
                type="text"
                style="min-width: 200px"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
            <!-- Location -->
            <b-form-group class="ml-sm-1 flex-grow-1">
              <label for="link-name" class="font-weight-bold">
                {{ $t("backoffice.settings.location-label") }}</label>
              <b-form-input
                id="link-name"
                v-model="currentLocation.location"
                list="my-list-id"
                type="text"
                :state="isValid"
                :placeholder="$t('form.type-placeholder')"
                @input="fetchOptions()"
              />
              <b-form-datalist id="my-list-id">
                <option v-for="(option, index) in options" :key="index">
                  {{ option.description }}
                </option>
              </b-form-datalist>
            </b-form-group>
          </div>
          <!-- All locations -->
          <div
            v-for="(location, index) in mapPlaces"
            :key="index"
            class="d-flex flex-wrap align-items-center"
          >
            <!-- Name -->
            <b-form-group class="flex-grow-1">
              <label for="link-name" class="font-weight-bold">
                {{ $t("backoffice.settings.name") }}</label>
              <b-form-input
                id="link-name"
                v-model="mapPlaces[index].name"
                type="text"
                style="min-width: 200px"
                :placeholder="$t('form.type-placeholder')"
                @input="fetchOptions(index)"
              />
            </b-form-group>
            <!-- Location -->
            <b-form-group class="ml-sm-1 flex-grow-1">
              <label for="link-name" class="font-weight-bold">
                {{ $t("backoffice.settings.location-label") }}</label>
              <b-form-input
                id="link-name"
                v-model="mapPlaces[index].location"
                type="url"
                :list="`my-list-id-${index}`"
                :placeholder="$t('form.type-placeholder')"
                @input="fetchOptions(index)"
              />
              <datalist
                v-for="(option, index) in options"
                :id="`my-list-id-${index}`"
                :key="index"
              >
                <option>{{ option.description }}</option>
              </datalist>
            </b-form-group>
            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="ml-50 text-primary pointer"
              @click="removeItem(index)"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <places-map
      ref="map"
      v-model="mapPlaces"
      :placeholder="$t('organizations.edit.locations.placeholder')"
      :locations="mapPlaces"
      class="mt-2"
      style="height: 400px"
    />
  </b-card>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import LogoPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import BannerPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import formValidation from '@core/comp-functions/forms/form-validation';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import PlacesMap from '@core/components/places-map-input/PlacesMap.vue';
import { Loader } from '@googlemaps/js-api-loader';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
import vSelect from 'vue-select';

export default {
  name: 'Locations',
  components: {
    PlacesMap,
    PlacesMapSelect,
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentLocation: {
        name: null,
        location: null,
      },
      locations: [],
      isLinkSectionActive: false,
      isFirstLinkSectionVisible: false,
      isFirstLocationSectionVisible: false,
      google: null,
      autocomplete: null,
      placesService: null,
      roomKey: '',
      bounds: null,
      hasRoom: false,
      selectedPlacesIds: [],
      options: [],
      mapPlaces: [],
      isValid: null,
      savedLOcations: [],
      isRemoving: false,
      roomSelected: {},
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    bannerUrl() {
      if (
        Array.isArray(this.currentCollective.header)
        && this.currentCollective.header.length
      ) {
        return getImageResource(this.currentCollective.header[0]);
      }
      return (
        getImageResource(this.currentCollective.header?.banner_url)
        || getImageResource(this.collective?.bgURL)
        || BannerPlaceholder
      );
    },
    logoUrl() {
      return (
        getImageResource(
          this.currentCollective.header?.logo_url
            || this.currentCollective.logoURL,
        ) || LogoPlaceholder
      );
    },
    linkLabelsOptions() {
      return ActiveLinksLabels;
    },
    isSaveEnabled() {
      return (
        this.form.name
        && this.form.slug
        && (this.form.accessibilityAux || this.form.accessibility)
        && (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return (
        !this.form.name
        || !this.form.slug
        || !this.form.visibility
        || !this.form.accessibility
      );
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    parentHasRoomsAppInstalled() {
      // console.log('this.$store.getters.apps');
      // console.log(this.$store.getters.apps);
      // console.log(this.$store.getters.installedApps);
      // TODO: ask for parent apps and check that it has Rooms installed
      return true;
    },
    rooms() {
      return this.$store.getters.parentRooms;
    },
    roomOptions() {
      if (this.rooms) {
        const roomsList = this.rooms.unpaginated;
        return roomsList?.map((x) => ({
          label: x.name,
          key: x.key,
        }));
      }
      return [];
    },
  },
  created() {
    this.hasRoom = !!this.currentCollective.room;
    if (this.hasRoom) {
      this.fetchRooms();
    }
    this.mapPlaces = this.currentCollective.room ? [this.currentCollective.room.locations] : this.currentCollective.locations;
    console.log('this.mapPlaces1', this.mapPlaces);
    this.mapPlaces = this.mapPlaces?.map((item) => ({
      location: item.address,
      name: item.label,
      latitude: item.latitude,
      longitude: item.longitude,
    }));
    console.log('this.mapPlaces2', this.mapPlaces);
    if (this.mapPlaces?.length > 0) {
      this.isFirstLocationSectionVisible = false;
    } else {
      this.isFirstLocationSectionVisible = true;
    }
    this.roomKey = this.hasRoom ? this.currentCollective.room.key : (this.roomOptions?.length > 0 ? this.roomOptions[0].key : '');
    if(!this.mapPlaces){
      this.mapPlaces = [];
    }
  },
  async mounted() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    this.google = await loader.load();

    this.autocompleteService = new this.google.maps.places.AutocompleteService();
    this.placesService = new this.google.maps.places.PlacesService(
      this.$refs?.map.getMap(),
    );

    // Bias the autocomplete results towards current map's viewport.
    this.$refs?.map?.getMap().addListener('bounds_changed', () => {
      this.bounds = this.$refs?.map.getMap().getBounds();
    });

    if (this.mapPlaces) {
      this.mapPlaces.forEach((location) => {
        this.selectedPlacesIds.push({
          code: `dummy-id-${location.key}`, // use dummy id to avoid fetching the placeId from geocoding.
          description: location.name,
        });
      });
    }
  },
  methods: {
    changeMap() {
      this.roomSelected = this.rooms.unpaginated.find((room) => room.key === this.roomKey);
      this.mapPlaces = [this.roomSelected.locations];
    },
    changeRoomToLocation() {
      if (this.hasRoom) {
        this.fetchRooms();
        this.mapPlaces = this.currentCollective.room ? [this.currentCollective.room.locations] : [];
      } else {
        this.mapPlaces = this.currentCollective.locations ?? [];
      }
    },
    addLocationCouple() {
      if (
        this.currentLocation.location
        && this.currentLocation.location !== null
      ) {
        this.handlePlaceSelected();
      } else if (this.isRemoving) {
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstLocationSectionVisible = true;
    },
    async saveLocations() {
      if (this.hasRoom) {
        if (Object.values(this.roomSelected).length > 0 || this.isRemoving) {
          try {
            await this.$store.dispatch('editCommunity', {
              settings: {
                roomKey: this.roomKey,
              },
            });
            // await this.$store.dispatch('addSettings', { room: this.roomSelected });
            this.notifySuccess(this.$t('backoffice.settings.messages.success'));
          } catch {
            this.notifyError(this.$t('backoffice.settings.messages.error'));
          }
        } else {
          this.notifyError(this.$t('backoffice.settings.messages.error'));
        }
        this.isRemoving = false;
      } else {
        // per eliminar la room vinculada
        if (this.currentCollective.room) {
          await this.$store.dispatch('editCommunity', {
            settings: {
              roomKey: 0,
            },
          });
        }
        this.addLocationCouple();
        this.mapPlaces = this.mapPlaces.filter(({ location }) => location !== '');
        this.mapPlaces?.length > 0
          ? (this.isFirstLocationSectionVisible = false)
          : true;
        this.currentLocation = {};
        const address = this.mapPlaces?.map((item) => ({
          name: item.location,
          label: item.name,
          latitude: item.latitude,
          longitude: item.longitude,
        }));
        if (address?.length > 0 || this.isRemoving) {
          try {
            await this.$store.dispatch('addLocations', { locations: address });
            this.notifySuccess(this.$t('backoffice.settings.messages.success'));
          } catch {
            this.notifyError(this.$t('backoffice.settings.messages.error'));
          }
        } else {
          this.notifyError(this.$t('backoffice.settings.messages.error'));
        }
        this.isRemoving = false;
        if(!this.mapPlaces){
          this.mapPlaces = [];
        }
      }
    },
    async fetchOptions(index) {
      const promise = new Promise((resolve) => {
        this.autocompleteService.getPlacePredictions(
          {
            input:
              this.currentLocation.location || this.mapPlaces[index].location,
            bounds: this.bounds,
          },
          (predictions, status) => {
            if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
              resolve([]);
              return;
            }

            resolve(predictions);
          },
        );
      });
      const predictions = await promise;

      this.options = predictions?.map(({ place_id: code, description }) => ({
        code,
        description,
      }));
      this.handlePlaceSelected(index);
    },
    async fetchRooms() {
      await this.$store.dispatch('getItems', {
        itemType: 'rooms',
        customName: 'parentRooms',
        communitySlug: this.currentCollective.parentCollective.slug,
        forceAPICall: true,
        page: 1,
        perPage: 200,
        requestConfig: {
          orderByDate: -1,
        },
      });
    },
    handlePlaceSelected(index) {
      const selectedOptions = this.options.filter(
        ({ description }) => description === this.currentLocation.location
          || description === this.mapPlaces[index]?.location,
      );
      const option = selectedOptions[selectedOptions.length - 1];
      if (!option) {
        return;
      }
      this.placesService.getDetails(
        { placeId: option.code },
        (placeResult, status) => {
          if (status === this.google.maps.GeocoderStatus.OK) {
            const postalCode = placeResult.address_components.find(
              ({ types }) => types.includes('postal_code'),
            )?.long_name;
            const locality = placeResult.address_components.find(({ types }) => types.includes('locality'))?.long_name;
            const region = placeResult.address_components.find(({ types }) => types.includes('administrative_area_level_1'))?.long_name;
            const location = {
              name:
                this.currentLocation.name
                || this.mapPlaces[index]?.name
                || option.description,
              latitude:
                this.currentLocation.location !== ''
                  ? placeResult.geometry.location.lat()
                  : '',
              longitude:
                this.currentLocation.location !== ''
                  ? placeResult.geometry.location.lng()
                  : '',
              postalCode,
              locality,
              region,
              location:
                this.currentLocation.location
                || this.mapPlaces[index]?.location,
            };
            if (index >= 0) {
              this.mapPlaces.splice(index, 1, location);
              this.isFirstLocationSectionVisible = false;
              this.currentLocation = {};
            } else {
              this.isValid = true;
              this.mapPlaces.push(location);
              this.isFirstLocationSectionVisible = false;
              this.currentLocation = {};
            }
          }
        },
      );
    },
    removeItem(index) {
      this.mapPlaces.splice(index, 1);
      this.isRemoving = true;
      if (this.mapPlaces?.length <= 0) {
        this.addLocationCouple();
      }
    },
  },
};
</script>
